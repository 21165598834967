import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import VNetworkGraph from "v-network-graph";
import "v-network-graph/lib/style.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/style/components.scss";
import "./assets/style/style.scss";
import { pageTitle } from 'vue-page-title';

const app = createApp(App);

// Firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import {getDatabase} from "firebase/database";
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: 'https://people2-admin.firebaseio.com',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
};

// Initialize Firebase
const _app =initializeApp(firebaseConfig);
export const auth = getAuth(_app);
// const db = getDatabase(_app)

app.use(store);
app.use(router);
app.use(pageTitle({ router, mixin: true }));
app.use(VNetworkGraph);
app.use(ElementPlus);

app.mount("#app");
