import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/relationships-network",
    name: "relationshipsNetwork",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WebView/RelationshipsNetwork.vue"),
    meta: {
      title: "Relationships Network",
    },
  },
  {
    path: "/",
    name: "testDriveManager",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TestDriveManager/Index.vue"),
    meta: {
      title: "Happily Test Drive Manager",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
