import {
  collection,
  query,
  doc,
  setDoc,
  where,
  // addDoc,
  getDocs,
  deleteDoc,
  getFirestore
} from "firebase/firestore";
//import { getDatabase, ref, child, get, query as $query } from "firebase/database";

import _ from "lodash";
import moment from 'moment-timezone'
export default {
  namespaced: true,
  state: {
    activeData: {},
    paddingData: [],
    activatedStatus: false
  },
  mutations: {
    setActiveData(state: any, result: any) {
      state.activeData = result
    },
    setPaddingData(state: any, result: any) {
      state.paddingData = result
    },
  },
  getters: {
    paddingData: (state: any) => {
      return state.paddingData
    },
    activeData: (state: any) => {
      return state.activeData
    },
    activatedStatus: (state: any) => {
      return state.activatedStatus
    },
  },
  actions: {
    async getDataTestDrive(
      { commit }: any
    ) {
      const dataCompany: any = {}
      const dataUserByCompany: any = {}
      const active: any = {}
      const padding: any = {}
      const db = getFirestore();
      const q1 = query(collection(db, "livedemo_company"));
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => { // console.log(doc.id, " => ", doc.data());
        const data = doc.data()
        data.id = doc.id
        if (!dataCompany[data.company]) {
          dataCompany[data.company] = data
        }
      })
      const q = query(collection(db, "livedemo"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => { // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        const data = doc.data()
        data.id = doc.id
        data.name = doc.data().fullname
        if (!dataUserByCompany[data.company]) {
          dataUserByCompany[data.company] = []
        }
        dataUserByCompany[data.company].push(data)
      });

      for (const id in dataUserByCompany) {
        dataUserByCompany[id] = _.sortBy(dataUserByCompany[id], ['createdate']);
        const _date = moment(dataUserByCompany[id][0]['createdate']).format('YYYY-MM-DD')
        const date_ID: any = moment(dataUserByCompany[id][0]['createdate']).format('dddd, MMM D')
        const company: any = {
          name: id,
          id: dataCompany[id] ? dataCompany[id].id : id,
          newCompany: dataCompany[id] ? false : true,
          users: dataUserByCompany[id]
        }
        if (dataCompany[id] && (dataCompany[id].status == "A" || dataCompany[id].status == "P")) {
          if (!active[_date]) {
            active[_date] = {
              date: date_ID,
              id: _date,
              status: "active",
              companyList: []
            }
          }
          active[_date]["companyList"].push(company)
        } else if ((dataCompany[id] && dataCompany[id].status !== "N") || (!dataCompany[id])) {//if(dataCompany[id].status=="P"){
          if (!padding[_date]) {
            padding[_date] = {
              date: date_ID,
              id: _date,
              status: "padding",
              companyList: []
            }
          }
          padding[_date]["companyList"].push(company)
        }
      }
      const _padding = _.orderBy(padding, (a) => new Date(a.id), ['asc'])
      const _active = _.orderBy(active, (a) => new Date(a.id), ['asc'])

      commit('setPaddingData', _padding)
      commit('setActiveData', _active)

        },
    async checkUser({ state} : any,data:any) : Promise<any> {
      state.activatedStatus=false
      const extit=_.filter(data.users,(o)=>{
        o.exist==true
      })
      if(extit.length>0){
        state.activatedStatus=true
      }
    //  for(const userid in data.users){
    //     const emailKey = data.users[userid].email.replace(/\./g, '||')
    //     const dbRef = ref(getDatabase());
    //     await get(child(dbRef, `users/${emailKey}`)).then((snapshot) => {
    //       if (snapshot.exists()) {
    //         state.activatedStatus=true
    //        // console.log("data",snapshot.val());
    //       } else {
    //         console.log("No data available");
    //       }
    //     }).catch((error) => {
    //       console.error(error);
    //     });

    //   }
      
    },
    async changeStatus(
      { commit, state }: any, data: any
    ): Promise<any> {
      
      const activeData:any=[]
      let paddingData:any=state.paddingData
      if (data.statusUpdate == "P") {

     const db = getFirestore();
        const q = query(collection(db, "livedemo_company"), where("status", "!=", "N"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc1) => {
          await setDoc(doc(db, "livedemo_company", doc1.id), {
            status: "N"
          }, { merge: true })
        })
        if (data.newCompany == true) {
          const newCityRef = doc(collection(db, "livedemo_company"));
          data.id=newCityRef.id
          // console.log("data.id",data.id)
              await setDoc(newCityRef,{
                  status: data.statusUpdate,
                  company: data.name,
                  createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
                })
          // await addDoc(collection(db, "livedemo_company"), {
          //   status: data.statusUpdate,
          //   company: data.name,
          //   createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
          // })
        } else {
          await setDoc(doc(db, "livedemo_company", data.id), {
            status: data.statusUpdate,

          }, { merge: true });
        }

        for(const i in paddingData){
          const company=_.filter(paddingData[i].companyList,(o=>{
            if( o.name==data.name){
              o.id=data.id
              o.newCompany=false
              return o
            }
          }))
          
          if(company.length>0){
            activeData.push({
              date: paddingData[i].date,
              id:paddingData[i].id,
              status: "active",
              companyList: company
            })
          const _company=_.filter(paddingData[i].companyList,(o=>{
              return o.name!==data.name
            }))
            if(_company.length>0){
            paddingData[i].companyList=_company           
          }else{
            if(paddingData.length==1){
              paddingData=[]
            }else{
              paddingData.splice(i, 1)
         
            }
          }
     
            commit('setPaddingData',paddingData)
            commit('setActiveData', activeData)
            break;
          }

        }

   

      } else {
        const db = getFirestore();
        await setDoc(doc(db, "livedemo_company", data.id), {
          status: data.statusUpdate,
          createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
        }, { merge: true });
        commit('setActiveData', [])
      }

      
    },
    async deleteUser(
      { commit, state}: any, data: any
    ): Promise<any> {
      const db = getFirestore();
      let paddingData:any=state.paddingData
       await deleteDoc(doc(db, "livedemo", data.id));
      
      for(const i in paddingData){
        for(const companyID in paddingData[i].companyList){
          const user=_.filter(paddingData[i].companyList[companyID].users,(o=>{
            return o.email!=data.email
          }))
          if(user.length!==paddingData[i].companyList[companyID].users.length){
            paddingData[i].companyList[companyID].users=user
            if(paddingData[i].companyList.length==1&&user.length==0)
            {
              paddingData=[]
            }
          }
        }
      }
      commit('setPaddingData', paddingData)
    }


  },
};
