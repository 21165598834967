import {
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  orderBy,
  limit,
  getFirestore
} from "firebase/firestore";
import { getDatabase, ref, get, orderByChild, equalTo, query as $query } from "firebase/database";

import moment from 'moment-timezone'
import _ from "lodash";
export default {
  namespaced: true,
  state: {
    dataNetwork: {},
    loadingDataNetwork: true,
    question_nps: [
      '-M4F5P10foOhqehfK3lv'
    ],
    question_enps: [
      '-L1QT78ajNkp6MpsLkBu'
    ],
    question_who5: [
      '-MdMv_XgThL0o2iunlhE',
      '-MdMw8kfpZtxnppGUA1f',
      '-MdMyavdF-YvuyzW6cA5',
      '-MdMz-TodEXbj3AC68oP',
      '-MdMzwfySVTjhOAGoxTP'
    ],
    question_culture: [
      '-MjPYFeALtFMAJkTEiFn',
      '-MjPZ15ImFhkjDiMI3gH',
      '-MjPZVq3sRBZJwtr7_Tx',
      '-MjP_2__thuvnVc_RY0k',
      '-MjP_R_bWBdQvFhf5BTY'
    ],
    question_toxic_behaviors: [
      '-NF448yoOZ5QL8cfE3P2'
    ]

  },
  mutations: {
    setDataNetwork(state: any, value: any) {
      state.dataNetwork = value
    },
    setLoadingDataNetwork(state: any, value: any) {
      state.loadingDataNetwork = value
    },
  },
  actions: {
    async getDataNetwork({ commit, state }: any ,obj:any) {
      const MAX_LIFE_TIME = 10;
      const getCachedData = (key: string) => {
        const text = sessionStorage.getItem(key);
        const data = text ? JSON.parse(text) : null;
        let result = null;
        if (data && data.date) {
          const date = new Date(data.date);
          const now = new Date();
          const diff = now.getTime() - date.getTime();
          const diffMinutes = Math.round(diff / 60000);
          if (diffMinutes < MAX_LIFE_TIME) {
            result = data.data;
          }
        }
        return result;
      };

      const setCachedData = (key: string, data: any) => {
        let n = sessionStorage.length;
        if (n >= 999) {
          while(n--) {
            const key = sessionStorage.key(n);
            if(key) {
              sessionStorage.removeItem(key);
            }  
          }
        }

        sessionStorage.setItem(key, JSON.stringify({
          date: new Date(),
          data: data
        }));
      };

      const debug = true;
      const start = Date.now();
      try {
        // console.log(`setLoadingDataNetwork(true)`);
        commit('setLoadingDataNetwork', true)

        const db = getDatabase();
        let cachedData: any;

        // Relationships Map
        const start_1 = Date.now();
        let userArray: Array<any> = [];
        let hasUserArray = false;

        const userArrayKey = `rel_maps:${obj.company}`
        cachedData = getCachedData(userArrayKey);
        
        if (cachedData) {
          userArray = cachedData;
          hasUserArray = true;
        } else {
          const dbRef = ref(db, "/rel_maps")
          const queryConstraints = [orderByChild("comp_id"), equalTo(obj.company)]
          const rel_maps = await get($query(dbRef, ...queryConstraints))
          hasUserArray = rel_maps.exists();
          userArray = rel_maps.val()
          setCachedData(userArrayKey, userArray)
        }
 
        const end_1 = Date.now();
        debug && console.log(`Execution time rel_maps(${Object.keys(userArray).length}): ${end_1 - start_1} ms`);

        // Team Master
        let teamMaster: Array<any> = [];
        let hasTeamMaster = false;
        const start_2 = Date.now();
        const teamMasterKey = `team:${obj.company}`
        cachedData = getCachedData(teamMasterKey);
        if (cachedData) {
          teamMaster = cachedData;
          hasTeamMaster = true;
        } else {
          const dbRef2 = ref(db, "/team")
          const queryConstraints2 = [orderByChild("company"), equalTo(obj.company)]
          const team = await get($query(dbRef2, ...queryConstraints2))
          hasTeamMaster = team.exists();
          teamMaster = team.val()
          setCachedData(teamMasterKey, teamMaster)
        }
        const end_2 = Date.now();
        debug && console.log(`Execution time team(${Object.keys(teamMaster).length}): ${end_2 - start_2} ms`);
  
        // User Master
        let userMaster: Array<any> = [];

        const start_3 = Date.now();
        const userMasterKey = `users:${obj.company}`
        cachedData = getCachedData(userMasterKey);
        if (cachedData) {
          _.each(cachedData, (email: any) => {
            const data = getCachedData(`user:${email}`);
            if (data) {
              userMaster[email] = data;
            }
          });
        } else {
          const dbRef3 = ref(db, "/users")
          const queryConstraints3 = [orderByChild("company"), equalTo(obj.company)]
          const users = await get($query(dbRef3, ...queryConstraints3))
          userMaster = users.val()
          if (userMaster) {
            for (const email in userMaster) {
              const key = `user:${email}`;
              const data: any = {};
              for (const i in userMaster[email]) {
                const user = userMaster[email][i];
                if ((Array.isArray(user) || _.isObject(user))) continue;
                data[i] = userMaster[email][i];
              }
              // console.log('data',data)
              setCachedData(key, data);
            }
          }
          setCachedData(userMasterKey, Object.keys(userMaster));
        }
        const end_3 = Date.now();
        debug && console.log(`Execution time users(${Object.keys(userMaster).length}): ${end_3 - start_3} ms`);
        
        



        
        
        let toxic_behaviors: Array<any> = [];
        // toxic_behaviors
        const start_4 = Date.now();
        // let toxicArray: Array<any> = [];
        let hastoxicArray = false;
        const toxicArrayKey = `toxic_behaviors:${obj.company}`
        
         cachedData = getCachedData(toxicArrayKey);

        if (cachedData) {
          toxic_behaviors = cachedData;

          hastoxicArray = true;
        } else {
          const toxicArray=Object()
          const dataItem:any = []
          const db = getFirestore();
          const q1 = query(collection(db, "users_questions"),where('company','==', obj.company),where('quest_id','in', state.question_toxic_behaviors));
          const querySnapshot1 = await getDocs(q1);
          querySnapshot1.forEach((doc) => { // console.log(doc.id, " => ", doc.data());
            const data = doc.data()
            data.id = doc.id
            const emailKey = data.users.replace(/\./g, '||')
            if(dataItem[emailKey] == undefined){
              dataItem[emailKey] = []
            }
            dataItem[emailKey].push(data)
          })
          //console.log('dataItem',dataItem['apichaya_p@dhas||com'])
          for(const key in userArray){
            let dateDataExist = 0
            const checkDataExists = _.orderBy(dataItem[key],['date'], ['desc'])
            
            for(let i=0; i<checkDataExists.length; i++){
              if(checkDataExists[i] && checkDataExists[i].answer !== undefined){
                //console.log('checkDataExists.date',checkDataExists[i].date)
                
                dateDataExist = checkDataExists[i].answer
                break
              }
            }
            if(dateDataExist>=0){
               //console.log('dateDataExist',key,dateDataExist)
              if(toxicArray[key] == undefined){
                toxicArray[key] = {toxic_behaviors:0}
              }
              toxicArray[key].toxic_behaviors= dateDataExist

            }
          }
          //toxicArray=toxic_behaviors
          toxic_behaviors=toxicArray
          setCachedData(toxicArrayKey, toxicArray)
        }
        const end_4 = Date.now();
        debug && console.log(`Execution time toxic(${Object.keys(toxic_behaviors).length}): ${end_4 - start_4} ms`);
        
        const dataNetwork:any = {}
        dataNetwork['nodes'] = {}
        dataNetwork['edges'] = []
        if (hasUserArray && hasTeamMaster) {
          
          // let userArray: Array<any> = [];
          // let teamMaster: Array<any> = [];
          // let userMaster: Array<any> = [];
          let dataTeamParent = Object()
          let allTeamSelect = Object()
          // userArray = rel_maps.val()
          // teamMaster = team.val()
          // userMaster = users.val()
          /* set edges most valuable of rel_weight in the same node */
          const data_relweight:any=[]
          _.forEach(userArray, (item,keyId:any)=> {
            const userEmail = keyId.replace(/\|\|/g, ".");
            if(userMaster[keyId]){
              const edges = item.edges
              _.forEach(edges, (edges_i)=> {
         
                if(edges_i.node1 == userEmail || edges_i.node2 == userEmail){
                  if(data_relweight[edges_i.node1+"_"+edges_i.node2] == undefined && data_relweight[edges_i.node2+"_"+edges_i.node1] == undefined){
                    data_relweight[edges_i.node1+"_"+edges_i.node2] = edges_i.rel_weight
                  }else{
                    if(data_relweight[edges_i.node1+"_"+edges_i.node2] && data_relweight[edges_i.node1+"_"+edges_i.node2] < edges_i.rel_weight){
                      data_relweight[edges_i.node1+"_"+edges_i.node2] = edges_i.rel_weight
                    }else if(data_relweight[edges_i.node2+"_"+edges_i.node1] && data_relweight[edges_i.node2+"_"+edges_i.node1] < edges_i.rel_weight){
                      data_relweight[edges_i.node2+"_"+edges_i.node1] = edges_i.rel_weight
                    }
                  }
                }
              });
            }
          });
          //console.log('data_relweight',data_relweight)
          /* End set edges most valuable of rel_weight in the same node */
          if(obj.team == '' || obj.team == undefined || obj.team.toLowerCase() == 'all'){
            const keyTeam = _.findKey(teamMaster, { 'parent': '' });
           
            obj.team = keyTeam
          }

          const start_4 = Date.now();
          const db_firestore = getFirestore();

          const organization1levelKey = `organization1level:${obj.company}-${obj.team}`;
          cachedData = getCachedData(organization1levelKey);
          // console.log(organization1levelKey, "organization1levelKey");
          // console.log(cachedData);
          // console.log(_.cloneDeep(cachedData), "cachedData")
          // cachedData = null;
          if (cachedData) {
            // console.log("cachedDataeee");
            const delay = async (delay = 1000, callback = (): any => { return null; }) => {        
              const delayPromise = (ms: any) => new Promise(res => setTimeout(res, ms))
              await delayPromise(delay)
              callback()
            }

            await delay(100, () => {
              const _data = cachedData;
              if(_data.parent){
                dataTeamParent = _data.parent
              }
            })

          } else {

            // const q = query(collection(db_firestore, "organization1level"), where("company", "==", obj.company), where("team_id", "==", obj.team));
            // const querySnapshot = await getDocs(q);
            const q = doc(db_firestore, "organization1level", `${obj.company}-${obj.team}`);
            const docSnap = await getDoc(q);
            if (docSnap.exists()) {
              const _data = docSnap.data()
              // console.log(_data, "_data");
              setCachedData(organization1levelKey, _.pick(_data, "parent"))
              if (_data.parent) {
                dataTeamParent = _data.parent
              }
            }
            
          }

          const end_4 = Date.now();
          debug && console.log(`Execution time organization1level(1): ${end_4 - start_4} ms`);

          // console.log(JSON.stringify(dataTeamParent), "dataTeamParent");
          // console.log(dataTeamParent, "dataTeamParent");
          // if (dataTeamParent) return;

          if(Object.keys(dataTeamParent).length == 0){
            dataTeamParent[obj.team] = true
          }
          allTeamSelect = dataTeamParent
          const allNode:any=[]
          const nodeCheck:any=[]
          const usersinTeam:any = {}
          // console.log("before: _.forEach(dataTeamParent");
          _.forEach(dataTeamParent, (value,key)=> {
            const teamId = key
            const userByTeam:any = _.filter(userArray, {team_id: teamId})
            if(userByTeam.length > 0){
              _.forEach(userByTeam, (item)=> {
                
                
                const keyUser:any = _.findKey(userArray, { 'firstname': item.firstname, 'lastname': item.lastname });
                if(keyUser){
                  if(!usersinTeam[keyUser]){
                    usersinTeam[keyUser] = true
                  }
                  const userEmail = keyUser.replace(/\|\|/g, ".");
                  if(!allNode[item.firstname + "_" + item.lastname]){
                    allTeamSelect[item.team_id] = true
                    allNode[item.firstname + "_" + item.lastname] = true
                    const userObj = Object()
                    userObj.fullName = item.firstname + " " + item.lastname
                    userObj.name = item.firstname + " " + item.lastname.charAt(0)
                    userObj.cuttingName = item.firstname.charAt(0)+item.lastname.charAt(0)
                    userObj.email = userEmail
                    userObj.profileImage = item.pic
                    userObj.teamId = (teamMaster[item.team_id]) ? item.team_id : ''
                    userObj.teamColor = (teamMaster[item.team_id] && teamMaster[item.team_id].color) ? teamMaster[item.team_id].color : '#747474'
                    userObj.teamName = item.team_name
                    userObj.impactRating = (userMaster[keyUser] && userMaster[keyUser].impact_rating) ? Math.round(userMaster[keyUser].impact_rating): 0
                    userObj.healthBar = (userMaster[keyUser] && userMaster[keyUser].healthbar) ? userMaster[keyUser].healthbar * 1: 0
                    
                    userObj.toxic= (toxic_behaviors[keyUser] && toxic_behaviors[keyUser].toxic_behaviors) ? toxic_behaviors[keyUser].toxic_behaviors : -1
                    dataNetwork['nodes'][userEmail] = {}
                    Object.assign(dataNetwork['nodes'][userEmail], userObj);
                  }
                  let edges : Array<any> = [];
                  
                  edges = item.edges
             
                  const res = edges.map(el => ({source:el.node1, target:el.node2, opacity:el.rel_weight}));
                  _.forEach(res, (res_i)=> {
                    if(res_i.source == userEmail || res_i.target == userEmail){
                      if(nodeCheck[res_i.source+"_"+res_i.target] == undefined && nodeCheck[res_i.target+"_"+res_i.source] == undefined){
                        nodeCheck[res_i.source+"_"+res_i.target] = true
                        if(userEmail == res_i.source){
                          
                          const keyUserEdge = res_i.source.replace(/\./g, "||");
                          
                          if(userMaster[keyUserEdge] && !allNode[userMaster[keyUserEdge].firstname + "_" + userMaster[keyUserEdge].lastname]){
                            const userObj = Object()
                            userObj.fullName = userMaster[keyUserEdge].firstname + " " + userMaster[keyUserEdge].lastname
                            userObj.name = userMaster[keyUserEdge].firstname + " " + userMaster[keyUserEdge].lastname.charAt(0)
                            userObj.cuttingName = userMaster[keyUserEdge].firstname.charAt(0)+userMaster[keyUserEdge].lastname.charAt(0)
                            userObj.email = res_i.source
                            if(userMaster[keyUserEdge].profilepic_small){
                              userObj.profileImage = userMaster[keyUserEdge].profilepic_small;
                            }else if (userMaster[keyUserEdge].profilepic_crop){
                              userObj.profileImage = userMaster[keyUserEdge].profilepic_crop;
                            }else if (userMaster[keyUserEdge].profilepic){
                              userObj.profileImage = userMaster[keyUserEdge].profilepic;
                            }else {
                              userObj.profileImage = "";
                            }
                            userObj.teamId = (userMaster[keyUserEdge]) ? userMaster[keyUserEdge].team : ''
                            userObj.teamColor = (teamMaster[userMaster[keyUserEdge].team] && teamMaster[userMaster[keyUserEdge].team].color) ? teamMaster[userMaster[keyUserEdge].team].color : '#747474'
                            userObj.teamName = teamMaster[userMaster[keyUserEdge].team].name
                            userObj.impactRating = (userMaster[keyUserEdge] && userMaster[keyUserEdge].impact_rating) ? Math.round(userMaster[keyUserEdge].impact_rating): 0
                            userObj.healthBar = (userMaster[keyUserEdge] && userMaster[keyUserEdge].healthbar) ? userMaster[keyUserEdge].healthbar * 1: 0
                            userObj.toxic= (toxic_behaviors[keyUserEdge] && toxic_behaviors[keyUserEdge].toxic_behaviors) ? toxic_behaviors[keyUserEdge].toxic_behaviors : -1
                            if((data_relweight[res_i.source+"_"+res_i.target] && data_relweight[res_i.source+"_"+res_i.target] >= 0.66) || (data_relweight[res_i.target+"_"+res_i.source] && data_relweight[res_i.target+"_"+res_i.source] >= 0.66)){
                              if(data_relweight[res_i.source+"_"+res_i.target]){
                                res_i.opacity = data_relweight[res_i.source+"_"+res_i.target]
                              }else{
                                res_i.opacity = data_relweight[res_i.target+"_"+res_i.source]
                              }
                              allTeamSelect[userMaster[keyUserEdge].team] = true
                              allNode[userMaster[keyUserEdge].firstname + "_" + userMaster[keyUserEdge].lastname] = true
                              dataNetwork['nodes'][res_i.source] = {}
                              Object.assign(dataNetwork['nodes'][res_i.source], userObj);
                            }
                          }
                          const keyUserEdgeTarget = res_i.target.replace(/\./g, "||");
                          if(userMaster[keyUserEdgeTarget] && !allNode[userMaster[keyUserEdgeTarget].firstname + "_" + userMaster[keyUserEdgeTarget].lastname]){
                            const userObj = Object()
                            userObj.fullName = userMaster[keyUserEdgeTarget].firstname + " " + userMaster[keyUserEdgeTarget].lastname
                            userObj.name = userMaster[keyUserEdgeTarget].firstname + " " + userMaster[keyUserEdgeTarget].lastname.charAt(0)
                            userObj.cuttingName = userMaster[keyUserEdgeTarget].firstname.charAt(0)+userMaster[keyUserEdgeTarget].lastname.charAt(0)
                            userObj.email = res_i.target
                            if(userMaster[keyUserEdgeTarget].profilepic_small){
                              userObj.profileImage = userMaster[keyUserEdgeTarget].profilepic_small;
                            }else if (userMaster[keyUserEdgeTarget].profilepic_crop){
                              userObj.profileImage = userMaster[keyUserEdgeTarget].profilepic_crop;
                            }else if (userMaster[keyUserEdgeTarget].profilepic){
                              userObj.profileImage = userMaster[keyUserEdgeTarget].profilepic;
                            }else {
                              userObj.profileImage = "";
                            }
                            userObj.teamId = (userMaster[keyUserEdgeTarget]) ? userMaster[keyUserEdgeTarget].team : ''
                            userObj.teamColor = (teamMaster[userMaster[keyUserEdgeTarget].team] && teamMaster[userMaster[keyUserEdgeTarget].team].color) ? teamMaster[userMaster[keyUserEdgeTarget].team].color : '#747474'
                            userObj.teamName = teamMaster[userMaster[keyUserEdgeTarget].team].name
                            userObj.impactRating = (userMaster[keyUserEdgeTarget] && userMaster[keyUserEdgeTarget].impact_rating) ? Math.round(userMaster[keyUserEdgeTarget].impact_rating): 0
                            userObj.healthBar = (userMaster[keyUserEdgeTarget] && userMaster[keyUserEdgeTarget].healthbar) ? userMaster[keyUserEdgeTarget].healthbar * 1: 0
                            userObj.toxic= (toxic_behaviors[keyUserEdgeTarget] && toxic_behaviors[keyUserEdgeTarget].toxic_behaviors) ? toxic_behaviors[keyUserEdgeTarget].toxic_behaviors : -1
                            if((data_relweight[res_i.source+"_"+res_i.target] && data_relweight[res_i.source+"_"+res_i.target] >= 0.66) || (data_relweight[res_i.target+"_"+res_i.source] && data_relweight[res_i.target+"_"+res_i.source] >= 0.66)){
                              if(data_relweight[res_i.source+"_"+res_i.target]){
                                res_i.opacity = data_relweight[res_i.source+"_"+res_i.target]
                              }else{
                                res_i.opacity = data_relweight[res_i.target+"_"+res_i.source]
                              }
                              allTeamSelect[userMaster[keyUserEdgeTarget].team] = true
                              allNode[userMaster[keyUserEdgeTarget].firstname + "_" + userMaster[keyUserEdgeTarget].lastname] = true
                              dataNetwork['nodes'][res_i.target] = {}
                              Object.assign(dataNetwork['nodes'][res_i.target], userObj);
                            }
                          }
                          
                          dataNetwork['edges'].push(res_i)
                        }else if(userEmail == res_i.target){
                          const keyUserEdge = res_i.target.replace(/\./g, "||");
                          
                          if(userMaster[keyUserEdge] && !allNode[userMaster[keyUserEdge].firstname + "_" + userMaster[keyUserEdge].lastname]){
                            const userObj = Object()
                            userObj.fullName = userMaster[keyUserEdge].firstname + " " + userMaster[keyUserEdge].lastname
                            userObj.name = userMaster[keyUserEdge].firstname + " " + userMaster[keyUserEdge].lastname.charAt(0)
                            userObj.cuttingName = userMaster[keyUserEdge].firstname.charAt(0)+userMaster[keyUserEdge].lastname.charAt(0)
                            userObj.email = res_i.target
                            if(userMaster[keyUserEdge].profilepic_small){
                              userObj.profileImage = userMaster[keyUserEdge].profilepic_small;
                            }else if (userMaster[keyUserEdge].profilepic_crop){
                              userObj.profileImage = userMaster[keyUserEdge].profilepic_crop;
                            }else if (userMaster[keyUserEdge].profilepic){
                              userObj.profileImage = userMaster[keyUserEdge].profilepic;
                            }else {
                              userObj.profileImage = "";
                            }
                            userObj.teamId = (userMaster[keyUserEdge]) ? userMaster[keyUserEdge].team : ''
                            userObj.teamColor = (teamMaster[userMaster[keyUserEdge].team] && teamMaster[userMaster[keyUserEdge].team].color) ? teamMaster[userMaster[keyUserEdge].team].color : '#747474'
                            userObj.teamName = teamMaster[userMaster[keyUserEdge].team].name
                            userObj.impactRating = (userMaster[keyUserEdge] && userMaster[keyUserEdge].impact_rating) ? Math.round(userMaster[keyUserEdge].impact_rating): 0
                            userObj.healthBar = (userMaster[keyUserEdge] && userMaster[keyUserEdge].healthbar) ? userMaster[keyUserEdge].healthbar * 1: 0
                            userObj.toxic= (toxic_behaviors[keyUserEdge] && toxic_behaviors[keyUserEdge].toxic_behaviors) ? toxic_behaviors[keyUserEdge].toxic_behaviors : -1
                            if((data_relweight[res_i.source+"_"+res_i.target] && data_relweight[res_i.source+"_"+res_i.target] >= 0.66) || (data_relweight[res_i.target+"_"+res_i.source] && data_relweight[res_i.target+"_"+res_i.source] >= 0.66)){
                              if(data_relweight[res_i.source+"_"+res_i.target]){
                                res_i.opacity = data_relweight[res_i.source+"_"+res_i.target]
                              }else{
                                res_i.opacity = data_relweight[res_i.target+"_"+res_i.source]
                              }
                              allTeamSelect[userMaster[keyUserEdge].team] = true
                              allNode[userMaster[keyUserEdge].firstname + "_" + userMaster[keyUserEdge].lastname] = true
                              dataNetwork['nodes'][res_i.target] = {}
                              Object.assign(dataNetwork['nodes'][res_i.target], userObj);
                            }
                          }
                          const keyUserEdgeTarget = res_i.source.replace(/\./g, "||");
                          if(userMaster[keyUserEdgeTarget] && !allNode[userMaster[keyUserEdgeTarget].firstname + "_" + userMaster[keyUserEdgeTarget].lastname]){
                            const userObj = Object()
                            userObj.fullName = userMaster[keyUserEdgeTarget].firstname + " " + userMaster[keyUserEdgeTarget].lastname
                            userObj.name = userMaster[keyUserEdgeTarget].firstname + " " + userMaster[keyUserEdgeTarget].lastname.charAt(0)
                            userObj.cuttingName = userMaster[keyUserEdgeTarget].firstname.charAt(0)+userMaster[keyUserEdgeTarget].lastname.charAt(0)
                            userObj.email = res_i.source
                            if(userMaster[keyUserEdgeTarget].profilepic_small){
                              userObj.profileImage = userMaster[keyUserEdgeTarget].profilepic_small;
                            }else if (userMaster[keyUserEdgeTarget].profilepic_crop){
                              userObj.profileImage = userMaster[keyUserEdgeTarget].profilepic_crop;
                            }else if (userMaster[keyUserEdgeTarget].profilepic){
                              userObj.profileImage = userMaster[keyUserEdgeTarget].profilepic;
                            }else {
                              userObj.profileImage = "";
                            }
                            userObj.teamId = (userMaster[keyUserEdgeTarget]) ? userMaster[keyUserEdgeTarget].team : ''
                            userObj.teamColor = (teamMaster[userMaster[keyUserEdgeTarget].team] && teamMaster[userMaster[keyUserEdgeTarget].team].color) ? teamMaster[userMaster[keyUserEdgeTarget].team].color : '#747474'
                            userObj.teamName = teamMaster[userMaster[keyUserEdgeTarget].team].name
                            userObj.impactRating = (userMaster[keyUserEdgeTarget] && userMaster[keyUserEdgeTarget].impact_rating) ? Math.round(userMaster[keyUserEdgeTarget].impact_rating): 0
                            userObj.healthBar = (userMaster[keyUserEdgeTarget] && userMaster[keyUserEdgeTarget].healthbar) ? userMaster[keyUserEdgeTarget].healthbar * 1: 0
                            userObj.toxic= (toxic_behaviors[keyUserEdgeTarget] && toxic_behaviors[keyUserEdgeTarget].toxic_behaviors) ? toxic_behaviors[keyUserEdgeTarget].toxic_behaviors : -1
                            if((data_relweight[res_i.source+"_"+res_i.target] && data_relweight[res_i.source+"_"+res_i.target] >= 0.66) || (data_relweight[res_i.target+"_"+res_i.source] && data_relweight[res_i.target+"_"+res_i.source] >= 0.66)){
                              if(data_relweight[res_i.source+"_"+res_i.target]){
                                res_i.opacity = data_relweight[res_i.source+"_"+res_i.target]
                              }else{
                                res_i.opacity = data_relweight[res_i.target+"_"+res_i.source]
                              }
                              allTeamSelect[userMaster[keyUserEdgeTarget].team] = true
                              allNode[userMaster[keyUserEdgeTarget].firstname + "_" + userMaster[keyUserEdgeTarget].lastname] = true
                              dataNetwork['nodes'][res_i.source] = {}
                              Object.assign(dataNetwork['nodes'][res_i.source], userObj);
                            }
                          }
                          dataNetwork['edges'].push(res_i)
                        }
                      }
                    }
                  });
                }
              })
            }
          })
          if(obj.links=='all_relational_data'){
            for(const key in dataNetwork['nodes']){
              const emailKey:any = key.replace(/\./g, '||')
              if(userMaster[emailKey]){
                const boss:any = userMaster[emailKey].boss
                const chk_nodes=dataNetwork['nodes'][boss]
                if(!chk_nodes){
                  const emailKeyboss:any = boss.replace(/\./g, '||')
                  const adduser:any = userArray[emailKeyboss]
                  if(adduser){
                    dataNetwork['nodes'][boss] = {}
                  dataNetwork['nodes'][boss]={
                    fullName: adduser.firstname + " " + adduser.lastname,
                    name: adduser.firstname + " " + adduser.lastname.charAt(0),
                    cuttingName: adduser.firstname.charAt(0) + adduser.lastname.charAt(0),
                    email: boss,
                    healthBar:  (userMaster[emailKeyboss] && userMaster[emailKeyboss].healthbar) ? userMaster[emailKeyboss].healthbar * 1: 0,
                    impactRating: (userMaster[emailKeyboss] && userMaster[emailKeyboss].impact_rating) ? Math.round(userMaster[emailKeyboss].impact_rating): 0,
                    profileImage: adduser.pic,
                    teamColor: adduser.teamColor,
                    teamId: adduser.team_id,
                    teamName: adduser.team_name,
                    toxic:(toxic_behaviors[emailKeyboss] && toxic_behaviors[emailKeyboss].toxic_behaviors) ? toxic_behaviors[emailKeyboss].toxic_behaviors : -1,
                  }

                  }
                  
                  dataNetwork['edges'].push({source:boss, target:key, opacity:0.1})
                }else{
                  dataNetwork['edges'].push({source:boss, target:key, opacity:0.1})
                }
              }
            }
            // dataNetwork['edges'].push({source:'pornsuda_c@dhas.com', target:'melissa_j@dhas.com', opacity:0.1})
            // dataNetwork['edges'].push({source:'melissa_j@dhas.com', target:'pornsuda_c@dhas.com', opacity:0.1})
            // dataNetwork['nodes']['pornsuda_c@dhas.com'] = {
            //   fullName: 'Pornsuda Chutipong',
            //   name: 'Pornsuda C',
            //   cuttingName: 'PC',
            //   email: 'pornsuda_c@dhas||com',
            //   healthBar: 75,
            //   impactRating: 48,
            //   profileImage: "https://firebasestorage.googleapis.com/v0/b/people2-admin.appspot.com/o/images%2Fprofiles2%2F-Kv0X8SSurYL3y1MZAWL%2Fsmall%2Fputthicha_k@dhas.com_20221215101438.jpg?alt=media&rand=167",
            //   teamColor: "#6C30CC",
            //   teamId: "-LBd7kp77goBhjnNbY0n",
            //   teamName: "Happily / Sales",
            //   toxic:4,
            // }

          }else if(obj.links=='line_of_management_only'){
            dataNetwork['edges']=[]
            // dataNetwork['nodes']=[]
            // if(obj.team == '' || obj.team == undefined || obj.team.toLowerCase() == 'all'){
              // _.forEach(dataTeamParent, (value,key)=> {
              //   const teamId = key
              //   const userByTeam:any = _.filter(userArray, {team_id: teamId})
              //   for(const item of userByTeam){
              //     const emailKey:any = key.replace(/\./g, '||')
              //   }
                
              // })
            for(const key in dataNetwork['nodes']){
              const emailKey:any = key.replace(/\./g, '||')
              
              if(userMaster[emailKey]&&usersinTeam[emailKey]){
                const boss:any = userMaster[emailKey].boss
                const chk_nodes=dataNetwork['nodes'][boss]

                if(!chk_nodes){
                  const emailKeyboss:any = boss.replace(/\./g, '||')
                  const adduser:any = userArray[emailKeyboss]
                  if(adduser){
                    dataNetwork['nodes'][boss] = {}
                  dataNetwork['nodes'][boss]={
                    fullName: adduser.firstname + " " + adduser.lastname,
                    name: adduser.firstname + " " + adduser.lastname.charAt(0),
                    cuttingName: adduser.firstname.charAt(0) + adduser.lastname.charAt(0),
                    email: boss,
                    healthBar:  (userMaster[emailKeyboss] && userMaster[emailKeyboss].healthbar) ? userMaster[emailKeyboss].healthbar * 1: 0,
                    impactRating: (userMaster[emailKeyboss] && userMaster[emailKeyboss].impact_rating) ? Math.round(userMaster[emailKeyboss].impact_rating): 0,
                    profileImage: adduser.pic,
                    teamColor: adduser.teamColor,
                    teamId: adduser.team_id,
                    teamName: adduser.team_name,
                    toxic:(toxic_behaviors[emailKeyboss] && toxic_behaviors[emailKeyboss].toxic_behaviors) ? toxic_behaviors[emailKeyboss].toxic_behaviors : 0,
                  }

                  }
                  
                  dataNetwork['edges'].push({source:boss, target:key, opacity:0.1})
                }else{
                  dataNetwork['edges'].push({source:boss, target:key, opacity:0.1})
                }
              }else{
                delete dataNetwork['nodes'][key]
              
              }
            }
          }
          
          
          const cohortsAttributes = ["well-being", "NPS", "eNPS", "culture_index"];
          if (_.indexOf(cohortsAttributes, obj.attribute) !== -1) {
            const start = Date.now();
            const namespace = obj.attribute;
            const groups = ["excellent", "fair", "critical"];

            //*
            // const dataItem:any=[];
            _.each(groups, async (group) => {
              let ns = namespace;
              if (ns == "well-being") {
                ns = "WHO-5";
              } else if (ns == "culture_index") {
                ns = "Culture";
              }
              const path = `/cohorts/${obj.company}/indexscores-${ns}-${group}`;
              const dbRef = ref(db, path);
              const rel_maps = await get($query(dbRef));
              // console.group(path, "path");
              const hasData = rel_maps.exists();
              const dataObject = rel_maps.val()
              // console.log(dataObject, "dataObject");
              if (!hasData) return;
              if (typeof dataObject == "object") {
                const usersMap = dataObject.users || {};
                // console.log(usersMap, "usersMap");
                for (const i in usersMap) {
                  const email = usersMap[i];
                  if (dataNetwork['nodes'][email]) {
                    // console.log(`Found ${namespace}.${group} for ${email}`);
                    if (obj.attribute == "well-being") {
                      if (group == "excellent") {
                        dataNetwork['nodes'][email].who5 = 100;
                      } else if (group == "fair") {
                        dataNetwork['nodes'][email].who5 = 69;
                      } else if (group == "critical") {
                        dataNetwork['nodes'][email].who5 = 49;
                      }
                    } else if (obj.attribute == "NPS") {
                      if (group == "excellent") {
                        dataNetwork['nodes'][email].nps = 100;
                      } else if (group == "fair") {
                        dataNetwork['nodes'][email].nps = 0;
                      } else if (group == "critical") {
                        dataNetwork['nodes'][email].nps = -100;
                      }
                    } else if (obj.attribute == "eNPS") {
                      if (group == "excellent") {
                        dataNetwork['nodes'][email].enps = 100;
                      } else if (group == "fair") {
                        dataNetwork['nodes'][email].enps = 0;
                      } else if (group == "critical") {
                        dataNetwork['nodes'][email].enps = -100;
                      }
                    } else if (obj.attribute == "culture_index") {
                      if (group == "excellent") {
                        dataNetwork['nodes'][email].culture = 100;
                      } else if (group == "fair") {
                        dataNetwork['nodes'][email].culture = 69;
                      } else if (group == "critical") {
                        dataNetwork['nodes'][email].culture = 50;
                      }
                    }
                  }
                }
              }
              // console.groupEnd();
            });
            //*/

            const end = Date.now();
            console.log(`${obj.attribute}: ${end - start} ms`);
          }

          
         // console.log(toxic_behaviors['opas@dhas||com'], "toxic_behaviors")
          commit('setDataNetwork',dataNetwork)
        } else {
          console.log("No data available");
          return null;
        }
      } finally {
        const end = Date.now()
        debug && console.log(`Execution time all: ${end - start} ms`)
        // console.log(`setLoadingDataNetwork(false)`);
        commit('setLoadingDataNetwork', false)
      }
    },
  },
  getters: {
    dataNetwork: (state: any) => {
      return state.dataNetwork
    },
    loadingDataNetwork: (state: any) => {
      return state.loadingDataNetwork
    },
  },
};
